import React from 'react';
import { Box, Typography } from '@mui/material';
import VendorForm from './VendorForm';
import useVendorStyles from './vendorStyles';

interface VendorsTemplateProps {
  title: string;
  mainTextContent: string[] | JSX.Element[];
}

const VendorsTemplate = ({
  mainTextContent,
  title,
}: VendorsTemplateProps): JSX.Element => {
  const { classes } = useVendorStyles();
  return (
    <main>
      <Box id="upload-target"></Box>
      <section>
        <Box>
          <Typography variant="h4">{title}</Typography>
          <Box className={classes.mainContent}>
            <article>
              {mainTextContent.map((content, idx) => (
                <p key={`mainContent_${idx}`}>{content}</p>
              ))}
            </article>

            <VendorForm />
          </Box>
        </Box>
      </section>
    </main>
  );
};

export default VendorsTemplate;
