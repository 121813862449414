import React from 'react';
import './App.css';
import PageWrapper from './PageWrapper/PageWrapper';

function App() {
  // App is now a wrapper for the page router
  return <PageWrapper />;
}

export default App;
